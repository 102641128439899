html, body {
  height: 100%;
  margin: 0;
}

#map {
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

@keyframes map_spinner {
  to {
    transform: rotate(360deg);
  }
}

.map_spinner:after {
  content: "";
  box-sizing: border-box;
  border: 5px solid #b4b4b499;
  border-top-color: #0009;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  animation: .6s linear infinite map_spinner;
  position: absolute;
  top: 50%;
  left: 50%;
}

/*# sourceMappingURL=index.3bbcd6b4.css.map */
